.flex-holder {
  display: flex;
  margin-top: 8px;

  .tip-icon {
    height: 16px;
    width: 16px;
    margin-right: 10px;
    margin-top: 3px;
  }

  .text {
    line-height: 1.42;
    // margin-bottom: 18px;
  }
}
