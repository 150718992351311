@import "./../../../styles/variables";

.page.page-processing {
  background-color: $colorGrey;

  .content {
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
    overflow-y: overlay;
    display: flex;
    flex-direction: column;

    .processing-holder {
      flex: 1;
      background: $colorWhite 0% 0% no-repeat padding-box;
      border-radius: 8px;
      margin-top: 16px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 16px;
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .lds-default {
        // display: inline-block;
        display: block;
        position: relative;
        width: 106px;
        height: 106px;
        // margin-left: auto;
        // margin-right: auto;
        animation: lds-default 3.2s linear infinite;
      }

      .lds-default div {
        position: absolute;
        width: 10px;
        height: 10px;
        // background: $colorPrimary;
        border-radius: 50%;
      }

      .lds-default div:nth-child(1) {
        top: 48px;
        left: 96px;
      }

      .lds-default div:nth-child(2) {
        top: 24px;
        left: 91px;
      }

      .lds-default div:nth-child(3) {
        top: 6px;
        left: 74px;
      }

      .lds-default div:nth-child(4) {
        top: 0px;
        left: 48px;
      }

      .lds-default div:nth-child(5) {
        top: 6px;
        left: 24px;
      }

      .lds-default div:nth-child(6) {
        top: 23px;
        left: 6px;
      }

      .lds-default div:nth-child(7) {
        top: 48px;
        left: 0px;
      }

      .lds-default div:nth-child(8) {
        top: 72px;
        left: 6px;
      }

      .lds-default div:nth-child(9) {
        top: 90px;
        left: 23px;
      }

      .lds-default div:nth-child(10) {
        top: 96px;
        left: 48px;
      }

      .lds-default div:nth-child(11) {
        top: 90px;
        left: 74px;
      }

      .lds-default div:nth-child(12) {
        top: 72px;
        left: 91px;
      }

      @keyframes lds-default {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      .processing-text {
        font-size: 21px;
        margin-top: 60px;
        color: $colorPrimaryText;
      }
    }
  }
}
