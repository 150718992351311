@import "./../../styles/variables";

footer {
  width: 100%;
  height: 37px;
  // background-color: $colorFooterBackground;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  justify-content: space-around;
  padding-left: 3%;
  padding-right: 3%;

  a,
  span {
    // font-size: 12px;
    // color: $colorFooterText;
    // text-align: center;
    line-height: 37px;
    cursor: pointer;
    // text-decoration: underline;

    // &:hover {
    //   color: $colorFooterText;
    //   text-decoration: underline;
    // }
  }
}

.contact-dialog {
  .contact-title {
    h2 {
      color: $colorPrimaryText;
      font-weight: 600;
      text-align: center;
    }
  }

  .MuiDialogContent-root {
    padding-top: 0px;
    padding-bottom: 0px;

    .contact-content-text {
      color: $colorPrimaryText;
      text-align: center;
      margin-bottom: 10px;

      .flex-holder {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        .contact-link {
          color: $colorPrimaryText;
          margin-top: 4px;

          svg {
            margin-top: -8px;
            margin-right: 3px;
          }

          &:hover {
            color: $colorPrimaryText;
          }
        }
      }
    }
  }

  .MuiDialogActions-root {
    padding-top: 0px;
  }
}

@media (max-width: 576px) {
  footer {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
