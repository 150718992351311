@import "./../../../styles/variables";

.page-product-page {
  .content {
    flex: 1;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 60px;
    overflow-y: overlay;

    .header-text {
      margin-top: 26px;
      // color: $colorPrimary;
      // font-size: 14px;
      // font-weight: bold;
      // text-transform: uppercase;
    }

    .banner {
      margin-top: 34px;
      margin-bottom: 34px;
      // width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-height: 42px;

      + .title {
        margin-top: 0px;
      }
    }

    .title {
      // font-size: 28px;
      // font-weight: 600;
      margin-top: 10px;
      // color: $colorPrimaryText;

      &:last-child {
        margin-bottom: 36px;
      }
    }

    .sub-title {
      // font-size: 21px;
      // color: $colorPrimaryText;
      margin-top: 10px;
      margin-bottom: 36px;
    }

    .essential {
      font-weight: bold;
      text-transform: uppercase;
    }

    .divider {
      // background-color: $colorDivider;
      // height: 1px;
      margin-top: 8px;
      margin-bottom: 24px;
    }

    .essentialItem-holder {
      margin-bottom: 36px;
    }

    .action-holder {
      .btn-second {
        margin-top: 16px;
      }
    }
  }
}
