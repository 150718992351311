@import "./../../../styles/variables";

.page-application-submitted {
  .content {
    flex: 1;
    padding-top: 66px; // to get same spacing as with back button
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 36px;
    overflow-y: overlay;

    .account-activated-image {
      display: block;
      // margin-left: auto;
      // margin-right: auto;
      // max-width: 228px;
      // max-height: 190px;
      height: 120px;
    }

    .congrats-title {
      font-size: 28px;
      font-weight: 800;
      margin-top: 16px;
      color: $colorPrimaryDarker;

      &:last-child {
        margin-bottom: 36px;
      }
    }

    .congrats-sub-title {
      font-size: 15px;
      margin-top: 5px;
    }

    .balance-holder {
      margin-top: 16px;
      border: 1px solid #99b5c5;
      border-radius: 8px;
      padding: 20px 16px 12px 16px;

      .balance-desc {
        font-size: 14px;
        text-align: left;
        color: $colorPrimaryGrey;
      }

      .balance-remain {
        font-size: 38px;
        color: $colorPrimaryDarker;
        text-align: left;
        font-weight: 800;
      }
    }

    .consent-holder {
      margin-top: 32px;
      .consent-title {
        color: $colorSecondaryDark;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
      .terms-holder {
        margin-top: 16px;
        align-items: flex-start;
        border: 1px solid $colorTermsHolderBorder;
        border-radius: 8px;
        margin-left: 0px;
        margin-right: 0px;
        padding: 16px;

        .terms-cb {
          margin-top: -9px;
          margin-left: -9px;
        }

        .terms {
          color: $colorPrimaryDarker;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;

          a {
            color: $colorPrimaryDarker;
            font-weight: 500;
            text-decoration: underline;
          }
        }
      }
    }

    .consent-holder + .action-holder {
      margin-top: 24px;
    }
    .action-holder {
      margin-top: 72px;
    }
  }
}
