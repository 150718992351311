@import "./../../../styles/variables";

.page-employment-details {
  .content {
    flex: 1;
    // padding-top: 36px;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 36px;
    overflow-y: overlay;

    .title {
      // font-size: 28px;
      // font-weight: 600;
      margin-top: 12px;
      // color: $colorPrimaryText;

      &:last-child {
        margin-bottom: 36px;
      }
    }

    .sub-title {
      // font-size: 21px;
      // color: $colorPrimaryText;
      margin-top: 10px;
      margin-bottom: 36px;
    }

    .divider {
      // background-color: $colorDivider;
      // height: 1px;
      margin-bottom: 24px;
    }

    .form-toggle-group {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      .toggle-btn {
        flex: 1;
        height: 48px;
        // background: $colorPrimaryToggleButtonBackground 0% 0% no-repeat padding-box;
        // border: 1px solid $colorPrimaryToggleButtonBorder;
        // border-radius: 8px;
        // color: $colorPrimaryButtonText;
        // font-size: 14px;

        &.Mui-selected {
          background-color: $colorPrimary;
          color: $colorWhite;
        }

        &:first-of-type {
          margin-right: 8px;
        }

        &:last-of-type {
          margin-left: 8px;
        }
      }
    }

    .action-holder {
      margin-top: 8px;
    }
  }
}
