@import "./../../styles/variables";

header {
  height: 45px;
  background-color: $colorWhite;
  box-shadow: 0px 3px 6px $colorBoxShadow;
  display: flex;

  .header-flex-holder {
    flex: 1;

    &.left {
      display: flex;
      align-items: center;

      .btn-cancel {
        margin-left: 12px;
        color: $colorPrimaryButtonText;
        font-size: 14px;
        text-transform: capitalize;
      }
    }

    &.middle {
      display: flex;
      align-items: center;
      justify-content: center;

      .logo-aspirasi {
        height: 26px;
      }

      .logo-lazada {
        height: 21px;
      }

      .power-by {
        font-size: 10px;
        color: $colorHeaderPartnerPowerByText;
        margin-right: 14px;
        margin-left: 18px;
      }
    }

    &.right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;

      // .language-toggle-group {
      //   margin-right: 10px;
      //   height: 24px;

      //   .language-toggle {
      //     border: 0;
      //     font-size: 12px;
      //     background-color: unset;

      //     &.Mui-selected {
      //       background-color: unset;

      //       span.MuiToggleButton-label {
      //         color: $colorPrimary;
      //         text-decoration: underline;
      //       }
      //     }

      //     &.en {
      //       border-right: solid 1px $colorLanguageToggleBorder;
      //     }

      //     span.MuiToggleButton-label {
      //       padding-top: 2px;
      //       font-weight: bold;
      //     }
      //   }
      // }
    }
  }
}

.embedded {
  header {
    display: none;
  }
}

.language-toggle-group {
  // margin-right: 10px;
  height: 24px;

  .language-toggle {
    border: 0;
    font-size: 12px;
    background-color: unset;

    &.Mui-selected {
      background-color: unset;

      span.MuiToggleButton-label {
        color: $colorPrimary;
        text-decoration: underline;
      }
    }

    &.en {
      border-right: solid 1px $colorLanguageToggleBorder;
    }

    span.MuiToggleButton-label {
      padding-top: 2px;
      font-weight: bold;
    }
  }
}
