@import "./../../../styles/variables";

.page-consent-confirmed {
  .content {
    flex: 1;
    padding-top: 66px; // to get same spacing as with back button
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 36px;
    overflow-y: overlay;

    .success-image {
      display: block;
      height: 120px;
      margin: auto;
    }

    .congrats-title {
      text-align: center;
      margin-top: 24px;
      color: $colorSecondaryDark;
      font-size: 24px;
      font-weight: 800;
      line-height: 29px;
    }

    .congrats-sub-title {
      text-align: center;
      margin-top: 24px;
      color: $color-grey-g50;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
    }

    .action-holder {
      margin-top: 72px;
    }
  }
}
