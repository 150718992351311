@import "./../../../styles/variables";

.page.page-offline-purchase {
  background-color: $colorGrey;

  .content {
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    overflow-y: overlay;
    color: $colorTextPrimary;

    .btn-back {
      visibility: visible;
    }

    .floor-wrapper {
      margin-top: 24px;
      .floor-content-heading {
        font-size: 24px;
        font-weight: 700;
      }
      .floor-content-holder {
        background: $colorWhite 0% 0% no-repeat padding-box;
        border-radius: 8px;
        padding: 25px;

        .floor-content-text {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 6px;
        }
      }
    }

    .box-wrapper {
      margin-top: 30px;
      .box-content-title {
        padding-left: 2px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .box-content-holder {
        box-shadow: 0px 2px 4px $colorBoxShadow;
        background: $colorWhite 0% 0% no-repeat padding-box;
        border-radius: 8px;
        padding: 20px;
        display: flex;
        font-size: 14px;

        .box-content-left {
          width: 30%;
          margin: auto;
          text-align: center;

          &.no-partner-logo {
            width: 25%;
          }

          .logo-partner {
            height: 100%;
            width: 100%;
            max-height: 64px;
          }
        }

        .box-content-right {
          width: 70%;
          margin: auto;
          padding-left: 10px;
        }

        .box-content-heading {
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }
  }

  .form-control-holder {
    margin-bottom: 0px;
    width: 100%;

    .offline-field {
      color: $colorTextPrimary;
      border: none !important;
      outline: none !important;
      border-radius: 0px !important;
      box-shadow: none !important;

      &:focus,
      &:focus-within {
        box-shadow: none !important;
      }
    }

    &.field-amount {
      margin: 10px 0px 18px 0px;
      height: 38px;

      .form-control-flex-wrap {
        display: flex;
      }

      .form-control-prefix-holder {
        height: 38px;
        vertical-align: middle;
        position: relative;

        .form-control-prefix {
          font-size: 22px;
          font-weight: 700;
          margin: 0;
          padding-left: 5px;
          padding-right: 5px;
          position: absolute;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        & + .offline-field {
          padding-left: 50px;
        }
      }

      .offline-field {
        height: 38px;
        font-size: 22px;
        font-weight: 700;
        border-bottom: 1px solid $colorGreyTextFieldBorder !important;
        box-shadow: 0 3px 2px -2px $colorWhite !important; // transparent

        &::placeholder {
          font-weight: 500;
        }

        &:focus,
        &:focus-within {
          border-bottom: 1px solid $colorGreyTextFieldBorder !important;
          box-shadow: 0 3px 2px -2px $colorBoxShadowDarker !important;
        }

        &.form-error-border {
          background-color: $colorWhite;
          border-bottom: 1px solid $colorFormInputErrorBorder !important;
          &:focus,
          &:focus-within {
            box-shadow: 0 3px 2px -2px $colorFormInputErrorBoxShadowFade !important;
          }
        }
      }
    }

    &.field-notes {
      display: flex;
      .offline-field {
        margin-left: 10px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 14px;
        font-weight: 500;
        height: unset;
      }
    }
  }

  .action-holder {
    background: $colorWhite 0% 0% no-repeat padding-box;
    // height: 116px;
    z-index: 100;
    box-shadow: 0px -3px 16px $colorBoxShadow;
    border-radius: 16px;
    margin-left: -1px;
    margin-right: -1px;
    margin-bottom: -1px;
    padding: 16px 36px 16px 36px;

    // .confirm-terms {
    //   font-size: 9px;
    //   color: $colorConfirmTermsText;
    //   text-align: center;
    //   margin-top: 8px;
    // }
  }
}
