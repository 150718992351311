@import "./../../../styles/variables";

.page-mandate {
  .content {
    flex: 1;
    padding-top: 66px; // to get same spacing as with back button
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 36px;
    overflow-y: overlay;

    .title {
      // font-size: 28px;
      // font-weight: 600;
      font-size: 28px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: left;
      color: #124A67;
      margin-top: 12px;

      &:last-child {
        margin-bottom: 36px;
      }
    }

    .sub-title {
      // font-size: 14px;
      // font-weight: bold;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color: #124A67;
      margin-top: 18px;
      margin-bottom: 20px;
    }

    // .form-control-holder {
    //   margin-bottom: 40px;
    // }

    .note {
      font-size: 14px;
      // font-weight: bold;
    }

    .divider {
      // background-color: $colorDivider;
      // height: 1px;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .action-holder {
      margin-top: 24px;
    }

    .contact-us {
      margin-top: 30px;
      text-align: center;
      font-size: 14px;

      a.contact-link {
        font-weight: bold;
        // color: $colorPrimaryText;
        text-decoration: underline;
      }
    }

    .partners-space {
      margin-top: 24px;
      text-align: center;

      img.partner-logo {
        height: 48px;
      }
    }
  }
}