@import "./../../../styles/variables";

.page.page-payment-plan {
  background-color: $colorGrey;

  .content {
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    overflow-y: overlay;

    .order-info-holder {
      height: 122px;
      background: $colorWhite 0% 0% no-repeat padding-box;
      border-radius: 8px;
      display: flex;
      margin-top: 24px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 16px;

      .col-left {
        flex: 1;

        .total-payment {
          color: $colorTxnCardGrey;
          font-size: 10px;
        }

        .total-payment-value {
          // color: $colorTxnCardPrimary;
          // font-size: 21px;
          // font-weight: bold;
          margin-top: 2px;
        }

        .merchant {
          // color: $colorTxnCardSecondary;
          font-size: 12px;
          margin-top: 4px;
        }

        .merchant-id {
          // color: $colorTxnCardSecondary;
          font-size: 12px;
          margin-top: 3px;
        }
      }

      .vertical-divider {
        // width: 1px;
        // background: $colorDivider;
        margin: 16px;
      }

      .col-right {
        .available-credit {
          color: $colorTxnCardBlack;
          font-size: 10px;

          .tip-icon {
            height: 14px;
            width: 14px;
            margin-left: 6px;
            margin-top: -6px;
            cursor: pointer;
          }
        }

        .available-credit-value {
          // color: $colorTxnCardSecondary;
          font-size: 16px;
          font-weight: bold;
          margin-top: 3px;
          min-width: 90px;
        }
      }
    }

    .select-plan-holder {
      margin-top: 16px;
      background: $colorWhite 0% 0% no-repeat padding-box;
      border-radius: 8px;
      padding: 20px 20px 0 20px;

      .select-plan-text {
        // color: $colorTxnCardSecondary;
        font-size: 14px;
        font-weight: 600;
        // margin-top: 4px;
        margin-bottom: 6px;
      }

      .form-radio-group-holder {
        .form-control-holder {
          margin-bottom: 0px;

          .form-control-label {
            width: 100%;
            min-height: 64px;
            margin-left: -7px;
            margin-right: 0px;

            .MuiFormControlLabel-label {
              flex: 1;
            }

            .form-control-label-content {
              display: flex;
              align-items: center;

              .description {
                // color: $colorTxnCardSecondary;
                flex: 1;
                font-size: 16px;
                font-weight: bold;
              }

              .icon-button {
                height: 38px;
                width: 38px;
                margin-right: -16px;

                .arrow {
                  // color: $colorTxnCardPrimary;
                  flex: none;
                  // margin-right: -10px;

                  &.dropdownOpen {
                    transform: rotate(90deg);
                    transition-duration: 0.4s;
                    transition-property: transform;
                  }

                  &.dropdownClosed {
                    transform: rotate(0);
                    transition-duration: 0.4s;
                    transition-property: transform;
                  }
                }
              }

              // .arrow {
              //   color: $colorTxnCardPrimary;
              //   flex: none;
              //   margin-right: -10px;

              //   &.dropdownOpen {
              //     transform: rotate(90deg);
              //     transition-duration: 0.4s;
              //     transition-property: transform;
              //   }

              //   &.dropdownClosed {
              //     transform: rotate(0);
              //     transition-duration: 0.4s;
              //     transition-property: transform;
              //   }
              // }
            }
          }

          .installment-holder {
            margin-top: 2px;
            margin-bottom: 21px;

            .tracking-holder {
              display: flex;
              margin-bottom: 12px;

              .tracking-point-holder {
                margin-right: 16px;
                margin-left: 8px;

                .tracking-point {
                  width: 9px;
                  height: 9px;
                  // background: $colorTxnInstallmentTrackingInactiveBg;
                  border-radius: 50%;
                  display: block;
                  margin-top: 7px;

                  &.blue {
                    background: $colorTxnCardPrimary;
                  }
                }

                .tracking-line {
                  width: 1px;
                  display: block;
                  // background-color: $colorTxnInstallmentTrackingInactiveBg;
                  height: calc(100% + 12px);
                  margin: auto;
                }
              }

              .tracking-content {
                flex: 1;
                display: flex;

                .installment-payment {
                  flex: 1;
                  font-size: 14px;
                  font-weight: bold;
                  // color: $colorTxnCardSecondary;
                }

                .installment-no {
                  color: $colorTxnCardGrey;
                  font-size: 14px;
                }
              }

              &:last-of-type {
                .tracking-point-holder {
                  .tracking-line {
                    height: 0;
                  }
                }
              }

              // &:first-of-type {
              //   .tracking-point-holder {
              //     .tracking-point {
              //       background: $colorTxnCardPrimary;
              //     }
              //   }
              // }
            }
          }

          // .divider {
          //   width: 100%;
          //   height: 1px;
          //   background: $colorDivider;
          // }
        }
      }
    }
  }

  .action-holder {
    background: $colorWhite 0% 0% no-repeat padding-box;
    height: 116px;
    z-index: 100;
    box-shadow: 0px -3px 16px $colorBoxShadow;
    border-radius: 16px;
    margin-left: -1px;
    margin-right: -1px;
    margin-bottom: -1px;
    padding: 16px 36px 0px 36px;

    .confirm-terms {
      font-size: 9px;
      color: $colorConfirmTermsText;
      text-align: center;
      margin-top: 8px;
    }
  }
}
