@import "./../../../styles/variables";

.page-landing {
  .content {
    flex: 1;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 46px;
    overflow-y: overlay;

    .title {
      // color: $colorPrimaryText;
      // font-size: 28px;
      // text-align: center;
      margin-top: 24px;
    }

    .description {
      // color: $colorTextSecondary;
      // text-align: center;
      // font-size: 14px;
      margin-top: 12px;
      margin-bottom: 36px;
    }

    .form-control-holder {
      margin-bottom: 16px;
    }

    .btn-login {
      margin-top: 8px;
    }

    .forgot-password {
      text-align: center;
      font-size: 14px;
      margin-top: 18px;
      color: $colorPrimary;
      text-decoration: underline;
      cursor: pointer;
    }

    .or-holder {
      display: flex;
      align-items: center;
      margin-top: 24px;
      margin-bottom: 19px;

      .divider {
        flex: 1;
        // height: 1px;
        // background-color: $colorDivider;
      }

      .or {
        color: $colorLandingDividerOrText;
        font-size: 14px;
        margin-left: 6px;
        margin-right: 6px;
        margin-bottom: 3px;
      }
    }
  }
}
