@import "./../../styles/variables";

.stepper-holder {
  margin-top: 7px;
  // margin-bottom: 18px;

  .step-text {
    // color: $colorPrimary;
    // font-size: 14px;
    // font-weight: bold;
  }

  .step-icon {
    margin-left: 4px;
    margin-bottom: 3px;
  }
}
