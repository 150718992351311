@import "./../../../styles/variables";

.page-password {
  .content {
    flex: 1;
    // padding-top: 36px;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 36px;
    overflow-y: overlay;

    .title {
      // font-size: 28px;
      // font-weight: 600;
      margin-top: 12px;
      // color: $colorPrimaryText;

      &:last-child {
        margin-bottom: 36px;
      }
    }

    .sub-title {
      // font-size: 21px;
      margin-top: 10px;
      margin-bottom: 36px;
    }

    .action-holder {
      margin-top: 40px;
    }
  }
}
