@import "./../../../styles/variables";

.page-contract-otp {
  .content {
    flex: 1;
    padding-left: 36px;
    padding-right: 36px;
    // padding-bottom: 16px;
    overflow-y: overlay;

    .title {
      // text-align: center;
      // font-size: 28px;
      // color: $colorPrimaryText;
      margin-top: 44px;
    }

    .sub-title {
      // color: $colorTextSecondary;
      // font-size: 14px;
      margin-top: 22px;
      // text-align: center;
      // line-height: 1.9;

      .phone {
        color: $colorTextPrimary;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .otp-input {
      height: 80px;
      justify-content: center;
      margin-top: 30px;

      input {
        height: 100% !important;
        margin-right: 6px !important;
        width: 48px !important;
        font-weight: bold;
        font-size: 36px;
        border: none;
        color: $colorTextPrimary;
        // border-bottom: 2px solid $colorPrimary;

        &:last-child {
          margin-right: 0px !important;
        }
      }

      &:focus-within input {
        outline: none;
        // border-bottom: 2px solid $colorPrimary;
        // -webkit-box-shadow: inset 0px -6px 8px -9px $colorPrimary, 0 6px 6px -7px $colorPrimary;
        // -moz-box-shadow: inset 0px -6px 8px -9px $colorPrimary, 0 6px 6px -7px $colorPrimary;
        // box-shadow: inset 0px -6px 8px -9px $colorPrimary, 0 6px 6px -7px $colorPrimary;
      }
    }

    .six-digit-text {
      font-size: 12px;
      width: 100%;
      text-align: center;
      margin-top: 18px;
      color: $colorTextPrimary;
      margin-bottom: 0px;
    }

    .action-holder {
      margin-top: 48px;
      margin-bottom: 48px;

      .btn-second {
        margin-top: 24px;
      }
    }
  }
}
