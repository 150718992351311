@import "./../../../styles/variables";

.page-scoring-loading {
  .content {
    flex: 1;
    padding-top: 66px; // to get same spacing as with back button
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 60px;
    overflow-y: overlay;

    .respond-loader {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 50%;
      color: $colorPrimaryScoringSpinner;
    }

    .title {
      // font-size: 28px;
      // font-weight: 600;
      margin-top: 12px;

      &:last-child {
        margin-bottom: 36px;
      }
    }

    .desc {
      text-align: center;
    }

    .validating {
      margin-top: 40px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .action-holder {
      margin-top: 40px;
    }
  }
}
