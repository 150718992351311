@import "./../../../styles/variables";

.page-kyc {
  .content {
    flex: 1;
    // padding-top: 36px;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 36px;
    overflow-y: overlay;

    .title {
      // font-size: 28px;
      // font-weight: 600;
      margin-top: 12px;
      // color: $colorPrimaryText;

      &:last-child {
        margin-bottom: 36px;
      }
    }

    .sub-title {
      // font-size: 21px;
      margin-top: 10px;
      margin-bottom: 36px;
    }

    .kyc-holder {
      margin-top: 28px;

      .kyc-row {
        display: flex;
        margin-left: -15px;

        .kyc-step-holder {
          position: relative;

          .kyc-step {
            width: 30px;
            height: 30px;
            background-color: $colorPrimary;
            border-radius: 50%;
            color: $colorWhite;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
          }

          .kyc-step-complete {
            width: 30px;
            height: 30px;
          }

          .kyc-step-line {
            margin-top: 20px;
            width: 1px;
            background-color: $colorPrimary;
            height: calc(100% - 20px - 20px - 30px);
            display: block;
            margin-left: auto;
            margin-right: auto;
            z-index: -1;
          }
        }

        .kyc-content-holder {
          margin-left: 15px;
          margin-bottom: 60px;

          .kyc-title {
            font-size: 21px;
            font-weight: bold;
          }

          .kyc-sub-title {
            margin-top: 10px;
            margin-bottom: 24px;
          }

          .kyc-image-holder {
            width: 100%;
            // background: $colorPrimaryTransparent 0% 0% no-repeat padding-box;
            // border: 1px solid $colorPrimaryLightGrey;
            border-radius: 8px;
            padding-top: 16px;
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 0px;

            &.kyc-image-complete-holder {
              background: $colorKycBackground; //$colorKycBackground 0% 0% no-repeat padding-box;
              border: 1px solid $colorKycBorder;
            }

            .kyc-placeholder {
              margin-top: 10px;
              display: block;
              margin-left: auto;
              margin-right: auto;
            }

            .kyc-image {
              width: 100%;
            }

            .kyc-action-holder {
              padding-top: 20px;
              padding-bottom: 20px;
              cursor: pointer;

              .kyc-action-icon {
                color: $colorPrimaryActionIcon;
                height: 38px;
                width: 38px;
                display: block;
                margin: auto;
              }
              .kyc-action-text {
                font-weight: bold;
                text-align: center;
                margin-top: 10px;
              }
            }
          }

          .ktc-form-error {
            font-size: 12px;
            text-align: center;
            margin-top: 8px;
            display: block;
            color: $colorFormInputErrorText;
          }
        }
        &:last-of-type {
          .kyc-content-holder {
            flex: 1;
            margin-bottom: 0px;

            .btn-next {
              margin-top: -8px;
            }

            .kyc-step-line {
              height: 0px;
            }
          }
        }
      }
    }

    .action-holder {
      margin-top: 8px;
    }
  }
}
