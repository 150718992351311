@import "./../../../styles/variables";

.page-personal-details {
  .content {
    flex: 1;
    // padding-top: 36px;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 36px;
    overflow-y: overlay;

    .title {
      // font-size: 28px;
      // font-weight: 600;
      margin-top: 12px;
      // color: $colorPrimaryText;

      &:last-child {
        margin-bottom: 36px;
      }
    }

    .sub-title {
      // font-size: 21px;
      // color: $colorPrimaryText;
      margin-top: 10px;
      margin-bottom: 36px;

      + .divider {
        margin-top: -12px;
      }

      &.small {
        margin-bottom: 15px;
      }
    }

    .divider {
      // background-color: $colorDivider;
      // height: 1px;
      margin-bottom: 24px;
    }

    .note {
      color: $colorPrimaryGrey;
      font-size: 12px;
    }

    .form-control-holder {
      .form-title {
        color: $colorPrimaryGrey;
        font-size: 12px;
      }
    }

    .terms-holder {
      margin-top: 16px;
      align-items: flex-start;
      border: 1px solid $colorTermsHolderBorder;
      border-radius: 8px;
      margin-left: 0px;
      margin-right: 0px;
      padding: 16px;

      .terms-cb {
        margin-top: -9px;
        margin-left: -9px;
      }

      .terms {
        color: $colorPrimaryDarker;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        a {
          color: $colorPrimaryDarker;
          font-weight: 500;
          text-decoration: underline;
        }
      }
    }

    .action-holder {
      margin-top: 24px;
      .btn-second {
        margin-top: 16px;
      }
    }
  }
}
