@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import "./assets/fonts/rawline.css";
@import "./styles/variables";

html {
  background-color: $colorHtmlBackground;
  height: 100%;
  // box-sizing: border-box;

  body {
    background-color: unset;
    font-family: "rawline", "Open Sans", sans-serif;
    overflow: unset;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  p {
    color: $colorPrimaryText;
    margin-bottom: 0px;
  }

  p,
  span {
    font-family: "rawline", "Open Sans", sans-serif;
  }
}

// *, *:before, *:after {
//   box-sizing: inherit;
// }

// .embedded{
//   .page {
//     margin-top: 0px;
//     margin-bottom: 0px;
//   }
// }

.embedded {
  .page {
    height: calc(100vh - 100px);
  }
}

.page {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  height: calc(100vh - 45px - 100px);
  background-color: $colorWhite;
  border: 1px solid $colorPageBorder;
  border-radius: 16px;
  // position: relative;
  display: flex;
  flex-direction: column;

  .btn-back {
    visibility: hidden;
    // display: none;
    margin-top: 10px;
    margin-left: -12px;
  }

  .form-control-holder {
    margin-bottom: 24px;

    .form-title {
      font-size: 14px;
      border-radius: 8px;
      color: $colorTextPrimary;
      margin-bottom: 6px;
      margin-left: 2px;
    }

    textarea.form-input {
      height: 128px;
      resize: none;
    }

    .form-input,
    .form-auto-complete {
      font-size: 14px;
      color: $colorBlack;
      padding-left: 15px;
      padding-right: 15px;
      height: 48px;
      border-radius: 8px;
      outline: 0;
      width: 100%;
      border: 1px solid $colorPrimaryLightGrey;

      &.form-disabled {
        background-color: rgba(239, 239, 239, 0.3);
      }

      &.form-error-border {
        border: 1px solid $colorFormInputErrorBorder;
        background-color: $colorFormInputErrorBackgroundColor;

        &:focus {
          box-shadow: 0px 0px 6px $colorFormInputErrorBoxShadow;
        }
      }

      &:focus {
        box-shadow: 0px 0px 6px $colorPrimaryLightGrey;
      }

      &::-webkit-input-placeholder {
        font-size: 14px;
        color: $colorFormPlaceHolder;
      }

      &:-moz-placeholder {
        font-size: 14px;
        color: $colorFormPlaceHolder;
      }
      &::-moz-placeholder {
        font-size: 14px;
        color: $colorFormPlaceHolder;
      }
      &:-ms-input-placeholder {
        font-size: 14px;
        color: $colorFormPlaceHolder;
      }

      &.form-select-input {
        padding: 0;
        background-color: $colorFormSelectBackground;

        .MuiSelect-root.MuiSelect-select {
          box-sizing: border-box;
          padding: 15px 24px 0px 15px;
          height: 100%;
          border-radius: 8px;
          // padding: 0px 24px 0px 15px;
          // display: flex;
          // align-items: center;

          @at-root .placeholder {
            color: $colorFormPlaceHolder !important;
          }
        }

        &:focus-within {
          box-shadow: 0px 0px 6px $colorPrimaryLightGrey;
        }

        &.form-error-border {
          &:focus-within {
            box-shadow: 0px 0px 6px $colorFormInputErrorBoxShadow;
          }

          .MuiSelect-icon {
            color: $colorFormInputErrorText;
          }
        }

        .MuiSelect-icon {
          margin-right: 5px;
          color: $colorPrimaryActionIcon;
        }
      }
    }

    .form-error {
      font-size: 12px;
      margin-left: 1px;
      margin-top: 1px;
      display: block;
      color: $colorFormInputErrorText;
    }

    .form-flex-holder {
      display: flex;

      .phone-prefix {
        line-height: 48px;
        text-align: center;
        display: block;
        width: 64px;
        height: 48px;
        background: $colorFormSelectBackground 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px $colorBoxShadow;
        border-radius: 8px;
        color: $colorTextPrimary;
        font-weight: bold;
        margin-right: 8px;
        flex-basis: 64px;
      }
      .form-input {
        flex: auto;
        width: unset;
      }
    }

    .form-auto-complete {
      padding: 0px;

      .form-text-field {
        .MuiInput-root {
          padding-bottom: 0px;

          &::before,
          &::after {
            border-bottom: none;
          }

          .MuiInput-input {
            box-sizing: border-box;
            height: 48px;
            padding-left: 15px;
            color: $colorBlack;

            &::-webkit-input-placeholder {
              color: $colorFormPlaceHolder;
              opacity: 1;
            }
            &:-moz-placeholder {
              color: $colorFormPlaceHolder;
              opacity: 1;
            }
            &::-moz-placeholder {
              color: $colorFormPlaceHolder;
              opacity: 1;
            }
            &:-ms-input-placeholder {
              color: $colorFormPlaceHolder;
              opacity: 1;
            }
          }

          .MuiAutocomplete-popupIndicator {
            color: $colorPrimaryLight;
            margin-right: 2px;
          }
        }
      }

      &:focus-within {
        box-shadow: 0px 0px 6px $colorPrimaryLightGrey;
      }

      &.form-error-border {
        &:focus-within {
          box-shadow: 0px 0px 6px $colorFormInputErrorBoxShadow;
        }

        .form-text-field .MuiAutocomplete-popupIndicator {
          color: $colorFormInputErrorText;
          margin-right: 2px;
        }
      }
    }
  }

  .btn-next {
    height: 48px; // auto; *experimenting this change whether break any UI*
    width: 100%;
    border-radius: 8px;
    font-family: "rawline", "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 15px;
    box-shadow: unset;

    &.MuiButton-contained {
      box-shadow: unset;
      // box-shadow: 0px 3px 6px $colorBoxShadow;
    }
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px $colorScrollbarTrackShadow;
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
    border-top: solid 58px transparent;
    border-bottom: solid 58px transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px $colorPrimaryLighter;
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
    border-top: solid 58px transparent;
    border-bottom: solid 58px transparent;
    border-radius: 10px;
  }
}

.custom-scrollbar {
  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px $colorScrollbarTrackShadow;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
    border-top: solid 58px transparent;
    border-bottom: solid 58px transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px $colorPrimaryLighter;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
    border-top: solid 58px transparent;
    border-bottom: solid 58px transparent;
    border-radius: 10px;
  }
}

.hide-scrollbar {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.page-holder {
  margin-top: 50px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  height: 640px;
  background-color: $colorWhite;
  border: 1px solid $colorPageBorder;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .page-inner {
    flex: 1;

    .page {
      &::-webkit-scrollbar {
        width: 20px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px 10px $colorScrollbarTrackShadow;
        border-left: solid 8px transparent;
        border-right: solid 8px transparent;
        border-top: solid 58px transparent;
        border-bottom: solid 58px transparent;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 10px 10px $colorPrimaryLighter;
        border-left: solid 8px transparent;
        border-right: solid 8px transparent;
        border-top: solid 58px transparent;
        border-bottom: solid 58px transparent;
        border-radius: 10px;
      }
    }
  }
}

.MuiMenu-list,
.MuiAutocomplete-listbox {
  .MuiMenuItem-root,
  .MuiAutocomplete-option {
    font-size: 14px;
    color: $colorPrimaryText;
    font-family: "rawline", "Open Sans", sans-serif;
    // white-space: normal;
    line-height: 1.3;
  }
}

.countdown-text {
  margin-top: 12px;
}

@media (orientation: landscape) and (max-height: 576px),
  (orientation: portrait) and (max-width: 576px) {
  .page {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
    height: calc(100vh - 45px);
    border-radius: 0px;

    // .content {
    //   padding-left: 20px;
    //   padding-right: 20px;
    // }
  }

  .embedded {
    .page {
      height: 100vh;
    }
  }
}
