@import "./../../../styles/variables";

.page-new-password {
  // margin-top: 50px;
  // margin-bottom: 16px;
  // margin-left: auto;
  // margin-right: auto;
  // width: 400px;
  // height: 640px;
  // background-color:$colorWhite;
  // border: 1px solid $colorPageBorder;
  // border-radius: 16px;
  // position: relative;
  // display: flex;
  // flex-direction: column;

  .content {
    flex: 1;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 46px;
    overflow-y: overlay;

    .title {
      // color: $colorPrimaryText;
      // font-size: 28px;
      // text-align: center;
      margin-top: 24px;
      margin-bottom: 64px;
    }

    .action-holder {
      margin-top: 40px;
    }
  }
}
