@import "./../../../styles/variables";

.PdfDialog {
  .dialog-bottom {
    border-top: solid 1px $colorPdfDialogBorder;

    .terms-holder {
      margin-top: 15px;
      margin-bottom: -5px;
      margin-right: 15px;
      margin-left: 15px;
      align-items: flex-start;

      .MuiCheckbox-root {
        margin-top: -10px;
        // margin-left: -14px;
      }

      .terms-text {
        font-size: 14px;
        color: $colorTermsText;
        font-weight: 600;

        a {
          color: $colorPrimary;
          // text-decoration: underline;
          // font-weight: bold;
          &:hover {
            color: $colorPrimaryDark;
          }
        }

        span {
          font-size: 12px;
          color: $colorTermsText;
          line-height: 1.5;
        }
      }

      .terms-sub-text {
        margin-top: 8px;
        font-size: 12px;
        color: $colorTermsText;
        line-height: 1.5;
      }
    }
  }

  .MuiDialogActions-root {
    padding: 10px 24px !important;
  }

  .MuiFab-root {
    box-shadow: none !important;

    &:not(:first-child) {
      margin-left: 12px !important;
    }

    &.MuiFab-primary {
      color: $colorWhite;
      background-color: $colorPrimary;
      &:hover {
        background-color: $colorPrimaryDark;
      }
      &.Mui-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &.MuiFab-secondary {
      color: $colorSecondary;
      background-color: $colorGrey;
      &:hover {
        background-color: $colorGrey;
      }
      &.Mui-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  .agreement-checkbox {
    margin-right: 15px;
    cursor: pointer;
    + span {
      text-align: left;
    }
  }
}
