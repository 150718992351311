@import "./../../../styles/variables";

.page-forgot-password {
  // margin-top: 50px;
  // margin-bottom: 16px;
  // margin-left: auto;
  // margin-right: auto;
  // width: 400px;
  // height: 640px;
  // background-color:$colorWhite;
  // border: 1px solid $colorPageBorder;
  // border-radius: 16px;
  // position: relative;
  // display: flex;
  // flex-direction: column;

  .content {
    flex: 1;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 46px;
    overflow-y: overlay;

    .title {
      // color: $colorPrimaryText;
      // font-size: 28px;
      // text-align: center;
      margin-top: 24px;
    }

    .description {
      // color: $colorTextSecondary;
      // text-align: center;
      // font-size: 14px;
      margin-top: 12px;
      margin-bottom: 60px;
    }

    .btn-next {
      margin-top: 16px;
    }

    .back-to-signin {
      text-align: center;
      font-size: 14px;
      margin-top: 18px;
      color: $colorPrimary;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
