@import "./../../../styles/variables";

.page-scoring-failure {
  .content {
    flex: 1;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 60px;
    overflow-y: overlay;

    .banner-holder {
      position: relative;
      width: 100%;
      // padding-top: 56.25%;
      margin-top: 66px;
      // background-repeat: no-repeat;
      // background-size: contain;
      padding: 68px 24px 24px 24px;
      background-size: cover;
      border-radius: 8px;

      .banner-title {
        position: relative;
        font-size: 28px;
        color: $colorWhite;
        font-weight: 600;
        // top: 62px;
        // left: 16px;
      }

      .banner-desc {
        margin-top: 6px;
        position: relative;
        font-size: 14px;
        color: $colorWhite;
        font-weight: 500;
        // top: 115px;
        // bottom: 18px;
        // left: 16px;
        text-align: left;
        padding-right: 20px;
      }
    }

    .text-desc {
      font-size: 14px;
      margin-top: 24px;

      .text-desc-point {
        display: flex;
        gap: 6px;
        .text-desc-point-dot {
          padding-left: 6px;
          font-size: 24px;
          line-height: 19px;
        }
        & + .text-desc-point {
          margin-top: 16px;
        }
      }

      a {
        color: $color-boost-red;
      }
    }

    .action-holder {
      margin-top: 36px;

      .btn-next + .btn-next {
        margin-top: 16px;
      }
    }
  }
}
