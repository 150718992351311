@import "./../../../styles/variables";

.page.page-successful-payment {
  background-color: $colorGrey;

  .content {
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
    overflow-y: overlay;

    .successful-holder {
      background: $colorWhite 0% 0% no-repeat padding-box;
      border-radius: 8px;
      margin-top: 16px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 16px;
      padding-bottom: 24px;

      .successful-logo {
        margin: 16px auto 22px auto;
        display: block;
      }

      // .title {
      //   color: $colorPrimaryText;
      //   font-weight: bold;
      //   font-size: 21px;
      //   text-align: center;
      // }

      .total-payment {
        margin-top: 12px;
        margin-bottom: 32px;
        height: 51px;
        background: $colorWhite 0% 0% no-repeat padding-box;
        // box-shadow: 0px 0px 16px $colorPrimaryBoxShadow;
        // border: 1px solid $colorPrimary;
        border-radius: 8px;
        width: 100%;
        text-align: center;
        color: $colorPrimaryText;
        display: flex;
        align-items: center;
        justify-content: center;

        .total-amount {
          font-weight: bold;
        }

        span {
          display: contents;
        }
      }

      // .divider {
      //   background-color: $colorDivider;
      //   height: 1px;
      //   width: 100%;
      // }

      .flex-holder {
        margin-top: 16px;
        display: flex;

        .paid-to {
          flex: 1;
          color: $colorPrimaryText;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 3px;

          .merchant {
            font-weight: bold;
          }
        }

        .date {
          color: $colorPaymentDateText;
          font-size: 12px;
          margin-top: 3px;
        }
      }

      .balance-holder {
        margin-top: 40px;
        background: $colorWhite 0% 0% no-repeat padding-box;
        border: 1px solid $colorRinggitHolderBorder;
        border-radius: 8px;
        padding: 14px 5px 12px 5px;

        .balance-credit {
          color: $colorPrimaryGrey;
          font-size: 12px;
          text-align: center;
        }

        .balance-amount {
          margin-top: 4px;
          font-weight: bold;
          color: $colorPrimary;
          font-size: 16px;
          text-align: center;
        }
      }

      .btn-next {
        margin-top: 32px;
      }
    }
  }
}
