@import "./../../../styles/variables";

.page-password-success {
  // margin-top: 50px;
  // margin-bottom: 16px;
  // margin-left: auto;
  // margin-right: auto;
  // width: 400px;
  // height: 640px;
  // background-color: $colorWhite;
  // border: 1px solid $colorPageBorder;
  // border-radius: 16px;
  position: relative;
  // display: flex;
  // flex-direction: column;

  .content {
    flex: 1;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 46px;
    overflow-y: overlay;

    .successful-logo {
      margin: 70px auto 56px auto;
      display: block;
    }

    .description {
      // text-align: center;
      // font-size: 21px;
      margin-bottom: 50px;
    }
  }
}
