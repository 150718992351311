@import "./../../../styles/variables";

.page.page-insurance {
  background-color: $colorGrey;

  .content {
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    overflow-y: overlay;

    .insurance-info-holder {
      background: $colorWhite 0% 0% no-repeat padding-box;
      margin-top: 0px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 16px;

      .insurance-logo {
        height: 80px;
        margin: 24px auto 22px auto;
        display: block;
      }

      .free-insurance-text {
        font-size: 21px;
        color: $colorPrimaryText;
        text-align: center;
      }

      .free-insurance-sub-text {
        color: $colorTextSecondary;
        font-size: 14px;
        text-align: center;
        margin-top: 5px;
      }

      .terms-holder {
        margin-top: 38px;
        margin-bottom: 32px;
        margin-right: 0px;
        margin-left: -14px;
        align-items: flex-start;

        .MuiCheckbox-root {
          margin-top: -10px;
          // margin-left: -14px;
        }

        .terms-text {
          font-size: 14px;
          color: $colorTermsText;
          font-weight: 600;

          a {
            color: $colorTermsText;
            text-decoration: underline;
            font-weight: bold;
          }

          span {
            font-size: 12px;
            color: $colorTermsText;
            line-height: 1.5;
          }
        }

        .terms-sub-text {
          margin-top: 8px;
          font-size: 12px;
          color: $colorTermsText;
          line-height: 1.5;
        }
      }
    }
  }

  .action-holder {
    background: $colorWhite 0% 0% no-repeat padding-box;
    height: 116px;
    box-shadow: 0px -3px 16px $colorBoxShadow;
    border-radius: 16px;
    margin-left: -1px;
    margin-right: -1px;
    margin-bottom: -1px;
    padding: 16px 36px 0px 36px;
    z-index: 1;

    .confirm-terms {
      font-size: 9px;
      color: $colorConfirmTermsText;
      text-align: center;
      margin-top: 8px;
    }
  }
}
