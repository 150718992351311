/* Note: Colors that will be used as JSS, please update in colors.js */

// official boost palette
$color-fade-red: #f8a6b4;
$color-boost-red: #ea0029;
$color-green: #15a5a1;
$color-fade-green: #6fe2df;
$color-yellow: #ffc629;
$color-fade-yellow: #ffd282;
$color-charcoal: #231f20;
$color-grey-g50: #58595b;
$color-grey-g100: #8a8a8c;
$color-grey-g200: #cdcdcd;
$color-grey-g300: #f3f4f4;
$color-grey-g400: #f9f9f9;
$color-pure-white: #ffffff;

// custom boost palette
$color-custom-boost-red-dark: #bd0a0a;

// // Base Color Palette (Backup Aspirasi blue)
// $colorWhite: #ffffff;
// $colorBlack: #000000;
// $colorGrey: #f4f5f7;
// $colorPrimaryLighter2: #e5f9ff;
// $colorPrimaryLighter: #b5ecfc;
// $colorPrimaryLight: #61caed;
// $colorPrimary: #05a0d3;
// $colorPrimaryDark: #005b8b;
// $colorPrimaryDarker: #124a67;
// $colorPrimaryLightGrey: #9eb0b9;
// $colorPrimaryGrey: #617e8d;
// $colorButtonDisabledBg: rgba(0, 0, 0, 0.12);

// Base Color Palette
$colorWhite: $color-pure-white;
$colorBlack: #000000;
$colorGrey: $color-grey-g300;
$colorPrimaryLighter2: #f5f5fa;
$colorPrimaryLighter: #ff8888;
$colorPrimaryLight: $color-fade-red;
$colorPrimary: $color-boost-red;
$colorPrimaryDark: $color-custom-boost-red-dark;
$colorPrimaryDarker: #124a67;
$colorPrimaryLightGrey: $color-grey-g100;
$colorPrimaryGrey: $color-grey-g50;
$colorButtonDisabledBg: rgba(0, 0, 0, 0.12);
$colorSecondaryLight: $color-grey-g100;
$colorSecondary: $color-grey-g50;
$colorSecondaryDark: $color-charcoal;

// Derived Color Palette
$colorPrimaryTransparent: rgba($colorPrimary, 41/255); //#05a0d329;
$colorPrimaryBoxShadow: $colorPrimaryTransparent;
$colorPrimaryText: $colorSecondaryDark;
$colorPrimaryButtonText: $colorSecondaryDark;
$colorPrimaryActionIcon: $colorPrimaryDarker;
$colorPrimaryScoringSpinner: $colorPrimary;
$colorPrimaryToggleButtonBackground: $colorPrimaryLighter2;
$colorPrimaryToggleButtonBorder: $colorPrimaryLighter;
$colorHtmlBackground: $colorPrimaryLighter2;
$colorScrollbarTrackShadow: $colorPrimaryLighter2;
$colorTermsHolderBorder: $colorPrimaryLightGrey;
$colorPaymentDateText: $colorPrimaryLightGrey;
$colorKycImageHolderBorder: $colorPrimaryLightGrey;
$colorKycImageHolderBackground: $colorPrimaryTransparent;

$colorPageBorder: #efeff6;
$colorTextPrimary: $color-charcoal; // #333333;
$colorTextSecondary: $color-grey-g100; // #717171;
$colorGreyTextFieldBorder: #b2b2b2;
$colorGreyTextFieldBorderFocused: #717171;
$colorRinggitHolderBorder: #dfdfdf;
$colorBoxShadow: #00000029;
$colorBoxShadowDarker: #00000055;

$colorTxnCardPrimary: $colorPrimary;
$colorTxnCardSecondary: $colorPrimaryDarker;
$colorTxnCardGrey: $colorPrimaryGrey;
$colorTxnCardBlack: #090a0a;
$colorTxnInstallmentTrackingInactiveBg: #ceced5;

$colorHeaderPartnerPowerByText: #8a8a8c;
$colorLanguageToggleBorder: #e1e0e5;
$colorFooterText: #959595;
$colorFooterBackground: #efeff6;
$colorDivider: #d5e3eb;
$colorLandingDividerOrText: #656565;
$colorTermsText: $color-charcoal;
$colorConfirmTermsText: #b2b2b2;
$colorPdfDialogBorder: #323639;
$colorKycBorder: #05d3b7 !important; // regardless theme
$colorKycBackground: #e8fffc !important; // regardless theme

$colorFormPlaceHolder: #b5b5b5;
$colorFormSelectBackground: #f5f5fa;
$colorFormInputErrorText: #d80012;
$colorFormInputErrorBorder: #d80012;
$colorFormInputErrorBoxShadow: #d80012;
$colorFormInputErrorBoxShadowFade: #d8001255;
$colorFormInputErrorBackgroundColor: #fff2f3;
