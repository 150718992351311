@import "./../../../styles/variables";

.page.page-contract {
  background-color: $colorGrey;

  .content {
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    overflow-y: overlay;

    .title-holder {
      margin-top: 16px;
      border-radius: 8px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 16px;
      background-color: $colorWhite;
      padding-bottom: 16px;

      .title {
        font-size: 14px;
        color: $colorPrimaryText;
      }
    }

    .contract-holder {
      background: $colorWhite 0% 0% no-repeat padding-box;
      border-radius: 8px;
      margin-top: 16px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 16px;
      padding-bottom: 16px;

      .contract-preview {
        .lc-main {
          font-size: 10pt;
          margin: 0px;
          width: 100%;

          .lc-tc {
            img {
              width: 150px !important;
            }
          }
          .lc-p {
            font-size: 10pt;
            margin-top: 10px;
            margin-bottom: 10px;
            line-height: 1.5;

            a {
              color: $colorPrimaryText;
              text-decoration: underline;
              font-weight: bold;
            }
          }
        }

        // width:600px; height:400px;
        // -webkit-transform:scale(.85);
        // -ms-transform:scale(.85);
        // transform:scale(.85);
        // -webkit-transform-origin:0 0;
        // -ms-transform-origin:0 0;
        // transform-origin:0 0;
        // border:4px solid green;
        // margin:0 0 -300px 0;
      }
    }
  }

  .action-holder {
    background: $colorWhite 0% 0% no-repeat padding-box;
    height: 116px;
    box-shadow: 0px -3px 16px $colorBoxShadow;
    border-radius: 16px;
    margin-left: -1px;
    margin-right: -1px;
    margin-bottom: -1px;
    padding: 16px 36px 0px 36px;
    z-index: 10;

    .confirm-terms {
      font-size: 9px;
      color: $colorConfirmTermsText;
      text-align: center;
      margin-top: 8px;
    }
  }
}
