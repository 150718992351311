@import "./../../styles/variables";

.icContainer {
  background-color: transparent;
  position: absolute;
  bottom: 20%;
  top: 11%;
  z-index: 1;
  height: 67%;
  width: 80%;
  outline: none;
  left: 10%;
  right: 10%;
  box-shadow: 0px 0px 200px 200px $colorBlack;
  opacity: 0.6;
  border-radius: 14px;
  overflow-x: hidden !important;
}

.dialogCamera {
  line-height: 0px;
  overflow-x: hidden !important;
}

.overlayImage {
  background-color: transparent;
  position: absolute;
  bottom: 20%;
  top: 8%;
  left: 7%;
  z-index: 2;
  height: 100%;
  width: 104%;
  // background-image: url("/images/icImageFrame.png");
  background-repeat: no-repeat;
  background-size: 83%;
  -webkit-background-size: 82% 73%;
}

.selfieContainer {
  background-color: transparent;
  position: absolute;
  bottom: 15%;
  top: 2%;
  z-index: 1;
  height: 80%;
  width: 50%;
  outline: none;
  left: 25%;
  right: 25%;
  box-shadow: 0px 0px 200px 200px $colorBlack;
  opacity: 0.6;
  border: solid;
  border-radius: 140px 140px 140px 140px;
}

@media screen and (max-width: 768px) {
  .icContainer {
    background-color: transparent;
    position: absolute;
    bottom: 20%;
    top: 11%;
    z-index: 1;
    height: 57%;
    width: 90%;
    outline: none;
    left: 5%;
    right: 5%;
    box-shadow: 0px 0px 200px 200px $colorBlack;
    opacity: 0.6;
    border-radius: 14px;
    overflow-x: hidden !important;
  }
  .overlayImage {
    background-color: transparent;
    position: absolute;
    bottom: 20%;
    top: 8%;
    left: 3%;
    z-index: 2;
    height: 80%;
    width: 116%;
    // background-image: url("/images/icImageFrame.png");
    background-repeat: no-repeat;
    background-size: 83%;
    -webkit-background-size: 82% 79%;
  }
  .selfieContainer {
    background-color: transparent;
    position: absolute;
    bottom: 25%;
    top: 2%;
    z-index: 1;
    height: 80%;
    width: 60%;
    outline: none;
    left: 21%;
    right: 8%;
    box-shadow: 0px 0px 200px 200px $colorBlack;
    opacity: 0.6;
    border: solid;
    border-radius: 240px 240px 240px 240px;
  }
}
