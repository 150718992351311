@import "./../../styles/variables";

.content-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  position: relative;

  &.mt-contra-back-button {
    margin-top: -30px;
  }

  .btn-back {
    // display: none;
    left: -12px;
    position: absolute;
    margin-top: 0px;
    margin-left: 0px;
  }

  .language-toggle-group {
    display: none;
    right: -12px;
    position: absolute;
    // right: 0;
  }

  .logo-paylater {
    max-height: 38px;
  }
}
