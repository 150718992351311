@import "./../../../styles/variables";

.page-scoring-success {
  .content {
    flex: 1;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 60px;
    overflow-y: overlay;

    .banner-holder {
      position: relative;
      width: 100%;
      // padding-top: 56.25%;
      margin-top: 66px;
      // background-repeat: no-repeat;
      // background-size: contain;
      padding: 68px 24px 24px 24px;
      background-size: cover;
      border-radius: 8px;

      .banner-title {
        position: relative;
        font-size: 28px;
        color: $colorWhite;
        font-weight: 600;
        // top: 62px;
        // left: 16px;
      }

      .banner-desc {
        position: relative;
        color: $colorWhite;
        // top: 115px;
        // bottom: 18px;
        // left: 16px;
        text-align: left;
        padding-right: 20px;
      }
    }

    .financial-amount-holder {
      margin-top: 16px;
      background: $colorWhite 0% 0% no-repeat padding-box;
      border: 1px solid $colorRinggitHolderBorder;
      border-radius: 8px;
      padding: 20px 16px 20px 16px;

      .financial-title {
        color: $colorPrimaryText;
        font-size: 14px;

        .tip-icon {
          height: 14px;
          width: 14px;
          margin-left: 8px;
          margin-top: -8px;
          cursor: pointer;
        }
      }

      .financial-amount {
        color: $colorPrimaryText;
        font-size: 38px;
        font-weight: bold;
        margin-top: 8px;
      }
    }

    .action-holder {
      margin-top: 18px;

      .btn-next {
        height: auto;
        padding: 24px;

        .MuiButton-label {
          display: flex;
          flex-direction: column;

          .btn-title {
            font-size: 21px;
            font-weight: bold;
            color: $colorWhite !important;
            text-transform: uppercase;
            text-align: left;
            width: 100%;
          }

          .btn-desc {
            font-size: 16px;
            color: $colorWhite !important;
            width: 100%;
            text-align: left;
            font-weight: normal;
            text-transform: initial;
            opacity: 0.7;
            letter-spacing: 0px;
          }
        }
      }
    }
  }
}
