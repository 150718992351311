@import "./../../styles/variables";

.MuiBackdrop-root.backdrop {
  z-index: 1000;
  background: rgba(0, 0, 0, 0.72);

  .spinner {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin: 4px;
    background-color: $colorPrimary;

    &.loader_1 {
      animation: zoominoutsinglefeatured 1s infinite;
      animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
    &.loader_2 {
      animation: zoominoutsinglefeatured 1s 150ms infinite;
      animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
    &.loader_3 {
      animation: zoominoutsinglefeatured 1s 300ms infinite;
      animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    @keyframes zoominoutsinglefeatured {
      0% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1.2, 1.2);
      }
      100% {
        transform: scale(1, 1);
      }
    }
  }
}
